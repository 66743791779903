var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.callActive
        ? _c(
            "v-btn",
            {
              staticClass: "mx-auto mt-4",
              attrs: { fab: "", color: "error darken-2" },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.callControl("hangup")
                }
              }
            },
            [_c("v-icon", [_vm._v("mdi-phone-hangup")])],
            1
          )
        : _c(
            "v-btn",
            {
              staticClass: "mx-auto mt-4",
              attrs: {
                disabled: !_vm.numberValid,
                fab: "",
                color: "success darken-2"
              },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.callControl("call")
                }
              }
            },
            [_c("v-icon", [_vm._v("mdi-phone")])],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }