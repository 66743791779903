<template>
    <div>
        <v-btn
            class="mx-auto mt-4"
            v-if="callActive"
            fab
            color="error darken-2"
            @click.stop="callControl('hangup')"
        >
            <v-icon>mdi-phone-hangup</v-icon>
        </v-btn>
        <v-btn
            v-else
            :disabled="!numberValid"
            class="mx-auto mt-4"
            fab
            color="success darken-2"
            @click.stop="callControl('call')"
        >
            <v-icon>mdi-phone</v-icon>
        </v-btn>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
    name: "call-control",
    components: {},
    props: {
        phoneNumber: {
            validator: (prop) =>
                typeof prop === "string" || typeof prop === "object",
            required: true,
        },
        numberValid: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {};
    },
    computed: {
        ...mapGetters("Calls", ["callActive"]),
    },
    methods: {
        ...mapActions("Calls", ["call", "hangup"]),
        callControl(event) {
            switch (event) {
                case "call":
                    this.call(this.phoneNumber);
                    this.$emit("call");
                    break;
                case "hangup":
                    this.hangup();
                    this.$emit("hangup");
                    break;
            }
        },
    },
};
</script>

<style scoped>
</style>