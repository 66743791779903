import { render, staticRenderFns } from "./CallControl.vue?vue&type=template&id=a5d33c52&scoped=true&"
import script from "./CallControl.vue?vue&type=script&lang=js&"
export * from "./CallControl.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a5d33c52",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBtn,VIcon})


/* hot reload */
if (module.hot) {
  var api = require("/home/circleci/project/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('a5d33c52')) {
      api.createRecord('a5d33c52', component.options)
    } else {
      api.reload('a5d33c52', component.options)
    }
    module.hot.accept("./CallControl.vue?vue&type=template&id=a5d33c52&scoped=true&", function () {
      api.rerender('a5d33c52', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/phone/components/CallControl.vue"
export default component.exports